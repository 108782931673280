<template>
  <div class="warp">
    <login-case type="login">
      <div class="hd flex">
        <div
          class="item flex-1 f18 cup"
          :class="{ active: type == 'pass' }"
          @click="tap('pass')"
        >
          账号登录
        </div>
        <div
          class="item flex-1 f18 cup"
          :class="{ active: type == 'code' }"
          @click="tap('code')"
        >
          验证码登录
        </div>
      </div>
      <template v-if="type == 'pass'">
        <div class="entry pt40">
          <div class="input mb15">
            <input type="text" placeholder="账号" v-model="params.identity" />
          </div>
          <div class="input">
            <input
              type="password"
              placeholder="密码"
              v-model="params.password"
            />
          </div>
        </div>
      </template>
      <template v-if="type == 'code'">
        <div class="entry pt40">
          <div class="input mb15">
            <input type="text" placeholder="手机号" v-model="params.identity" />
          </div>
          <div class="input flex mb15">
            <input
              type="text"
              placeholder="图形验证码"
              class="flex-1"
              v-model="imgCode"
            />
            <img :src="imgUrl" class="graph mr30 cup" @click="getImgCode" />
          </div>
          <div class="input flex">
            <input
              type="text"
              placeholder="短信验证码"
              class="flex-1"
              v-model="params.code"
            />
            <span class="code cup f18 mr30" @click="getCode">{{
              getCodeText
            }}</span>
          </div>
        </div>
      </template>
      <div class="login-btn f18 text-center mt40 cup" @click="getLogin">
        登录
      </div>

      <el-dialog
        title="绑定手机号"
        :visible.sync="dialogVisible"
        custom-class="mydialog"
        width="492px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true"
      >
        <div class="entry">
          <div class="input mb15">
            <input type="text" placeholder="手机号" v-model="params.identity" />
          </div>
          <div class="input flex mb15">
            <input
              type="text"
              placeholder="图形验证码"
              class="flex-1"
              v-model="imgCode"
            />
            <img :src="imgUrl" class="graph mr30 cup" @click="getImgCode" />
          </div>
          <div class="input flex">
            <input
              type="text"
              placeholder="短信验证码"
              class="flex-1"
              v-model="params.code"
            />
            <span class="code cup f18 mr30" @click="getCode('WxBindMobile')">{{
              getCodeText
            }}</span>
          </div>
          <div class="login-btn f18 text-center mt30 cup" @click="getMobile">
            确认绑定
          </div>
        </div>
      </el-dialog>
    </login-case>
    <p class="web">
      <a href="http://beian.miit.gov.cn/" target="_blank">
        备案号：粤ICP备2023066121号-6</a
      >
    </p>
  </div>
</template>

<script>
import loginCase from '../../components/loginCase.vue'
import { setItem } from '../../assets/js/storage.js'
export default {
  name: 'login',
  components: {
    loginCase
  },
  data() {
    return {
      type: 'pass',
      params: {
        identity: '',
        code: '',
        password: ''
      },
      imgCode: '',
      imgUrl: '',
      num: 60,
      getCodeText: '获取验证码',
      timer: null,
      code: '',
      dialogVisible: false
    }
  },
  mounted() {
    this.code = this.$route.query.code
    if (this.code) {
      this.getWxLogin()
    }
    this.getImgCode()
  },
  methods: {
    async getLogin() {
      if (this.type == 'pass') {
        this.loginPassword()
      } else {
        this.loginCode()
      }
    },
    // 账号+密码
    async loginPassword() {
      if (!this.params.identity) return this.$message.warning('请输入账号/手机')
      if (!this.params.password) return this.$message.warning('请输入密码')
      var params = {
        identity: this.params.identity,
        password: this.params.password,
        isData: 1
      }
      var res = await this.$api.post('/api/login/index', params)
      if (res.code == 0) {
        this.$message.success('登录成功')
        var userInfo = res.data
        setItem('user', userInfo)
        this.$store.dispatch('setUserModal')
        if (this.$route.query.str) {
          var obj = JSON.parse(this.$route.query.str)
          this.$router.push(obj[0])
        } else {
          this.$router.push('/')
        }
      }
    },
    // 微信登录
    async getWxLogin() {
      var params = {
        code: this.code
      }
      var res = await this.$api.post('/api/thirdBind/wxCodeLogin', params)
      if (res.code == 0) {
        var data = res.data
        if (data.isBind) {
          this.getUser()
        } else {
          this.dialogVisible = true // 微信登录成功后弹窗绑定手机
        }
      }
    },
    // 绑定手机号
    async getMobile() {
      if (!this.phoneFun(this.params.identity))
        return this.$message.warning('请输入正确的手机号码')
      if (!this.imgCode) return this.$message.warning('请输入图形验证码')
      if (!this.params.code) return this.$message.warning('请输入短信验证码')
      var params = {
        mobile: this.params.identity,
        code: this.params.code
      }
      var res = await this.$api.post('/api/member_third/bind', params)
      if (res.code == 0) {
        this.getUser()
      }
    },
    phoneFun(val) {
      var myreg = /^1[3-9]\d{9}$/
      if (!myreg.test(val)) {
        // console.log('手机号格式不正确')
        return false
      } else {
        // console.log('手机号格式正确')
        return true
      }
    },
    // 获取用户信息，并跳转首页
    async getUser() {
      var use = await this.$api.get('/api/member_center/index')
      if (use.code == 0) {
        this.$message.success('登录成功')
        var userInfo = use.data
        setItem('user', userInfo)
        this.$store.dispatch('setUserModal')
        this.$router.push('/')
      } else {
        // 顺便判断一下是否登录过期
        this.user = ''
        localStorage.removeItem('user')
      }
    },
    async loginCode() {
      if (!this.phoneFun(this.params.identity))
        return this.$message.warning('请输入正确的手机号码')
      if (!this.imgCode) return this.$message.warning('请输入图形验证码')
      if (!this.params.code) return this.$message.warning('请输入短信验证码')
      var params = {
        mobile: this.params.identity,
        code: this.params.code,
        isData: 1
      }
      var res = await this.$api.post('/api/login/code', params)
      if (res.code == 0) {
        this.$message.success('登录成功')
        var userInfo = res.data
        setItem('user', userInfo)
        this.$store.dispatch('setUserModal')
        if (this.$route.query.str) {
          var obj = JSON.parse(this.$route.query.str)
          this.$router.push(obj[0])
        } else {
          this.$router.push('/')
        }
      }
    },
    tap(value) {
      this.password = ''
      this.type = value
      if (value == 'code') {
        this.getImgCode()
      }
    },
    //获取短信验证码
    async getCode(type) {
      const _this = this
      if (this.getCodeText !== '获取验证码') {
        return false
      }
      if (!/^1[3-9]\d{9}$/.test(this.params.identity)) {
        return this.$message.warning('请输入正确的手机号码')
      }
      var params = {
        mobile: this.params.identity,
        t: type == 'WxBindMobile' ? 'bindMobile' : 'login',
        smsCaptchaCode: this.imgCode
      }
      var res = await this.$api.post(
        '/api/common/sendSmsCodeWithCaptcha',
        params
      )
      if (res.code == 0) {
        this.getCodeText = this.num + 's后重试'
        this.timer = setInterval(() => {
          if (_this.num === 1) {
            _this.getCodeText = '获取验证码'
            _this.num = 60
            clearInterval(_this.timer)
            _this.getImgCode()
          } else {
            _this.num--
            _this.getCodeText = this.num + 's后重试'
          }
        }, 1000)
      }
    },
    //获取图形验证码
    async getImgCode() {
      var params = {
        w: 105,
        h: 34,
        t: this.$util.random(1000, 9999)
      }
      var res = await this.$api.get('/app/captcha/sendSms', params, {
        responseType: 'arraybuffer'
      })
      var data =
        'data:image/png;base64,' +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )
      this.imgUrl = data
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  position: relative;
  .web {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 40px;
  }
}
/deep/ .mydialog {
  border-radius: 10px;
  .login-btn {
    height: 40px;
    line-height: 40px;
  }
  .el-dialog__header {
    border-bottom: 1px solid #f4f4f4;
  }
  .el-dialog__body {
    padding: 30px 80px;
  }
}
.login-btn {
  width: 100%;
  height: 62px;
  border-radius: 62px;
  line-height: 62px;
  background-color: #6672fb;
  color: #ffffff;
}
.entry {
  .input {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #d9d9d8;
    .code {
      color: #6672fb;
      font-weight: bold;
    }
    .graph {
      width: 105px;
      height: 34px;
    }
    input {
      width: 100%;
      line-height: 38px;
      border-radius: 40px;
      padding: 0 20px;
      border: none;
      box-sizing: border-box;
      font-size: 14px;
    }
    input::-webkit-input-placeholder {
      color: #999999;
      font-size: 14px;
    }
  }
}
.hd {
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #efefef;
  margin-top: 50px;
  .item {
    color: #999999;
    &.active {
      color: #333333;
    }
    &:first-child {
      border-right: 1px solid #efefef;
    }
  }
}
</style>
